import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import BaseToast from '@/components/base/BaseToast/BaseToast';
import { ByPageData } from '@/types/interface';
import { format, parseISO } from 'date-fns';
import {
  PageRequest,
  CreateTaskList,
  CreateTaskListSubTask,
  DuplicateTaskList,
  TaskListId,
  TaskListSubTaskId,
  UpdateTaskList,
  UpdateTaskListSubTask,
  UpdateTaskStatus,
  AttendancesProps,
  TaskDetail,
  SubTaskDetail,
  TaskListComment,
  TaskListHistoryDetails,
  TaskProps,
  TaskListQueryReportOptionsRequest,
  TaskListReportOption,
  TaskListQueryPreivewPhotoRequest,
  TaskListBriefPhoto,
  TaskListQueryPhotoRequest,
} from './interface';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { resetMyPage } from './taskListSlice';
import { scheduleApi } from '../schedule/scheduleAPI';

const URL = '/api/TaskList';
// serializeQueryArgs 確保每個查詢的唯一性，避免緩存錯誤、重複請求
// merge 將新加載的數據合併到當前的緩存中
// forceRefetch 當頁面參數改變時強制重新加載
// providerTags 將緩存返回的一個或多個標籤
export const taskListApi = createApi({
  reducerPath: 'taskListApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'TaskList',
    'Task',
    'PagedTaskList',
    'ProjectUsers',
    'SubTaskDetail',
    'TaskListDetail',
    'Comments',
    'DeleteTask',
  ],
  endpoints: (builder) => ({
    getQueryAttendancesReturnGroupedByPage: builder.query<
      AttendancesProps,
      PageRequest
    >({
      query: ({ GroupId, PageNumber, PageCount }) => ({
        url: `${URL}/QueryAttendancesReturnGroupedByPage`,
        method: 'GET',
        params: { GroupId, PageCount, PageNumber },
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.data.map(({ taskListId }) => ({
                type: 'Task' as const,
                id: taskListId,
              })),
            ]
          : [{ type: 'Task', id: 'My' }];
      },

      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems) => {
        // 處理更新的數據
        const updatedData = newItems.data
          .map((newTask) => {
            const existingTaskIndex = currentCache.data.findIndex(
              (task) => task.taskListId === newTask.taskListId,
            );

            if (existingTaskIndex !== -1) {
              currentCache.data[existingTaskIndex] = newTask;
              return null;
            }

            return newTask;
          })
          .filter((task): task is TaskProps => task !== null);
        // 合併新資料和已存在的資料
        const mergedData = [...updatedData, ...currentCache.data].sort(
          (a, b) => b.taskListId - a.taskListId,
        );
        currentCache.data = mergedData;
        currentCache.currentPageCount = newItems.currentPageCount;
        currentCache.pageCount = newItems.pageCount;
        currentCache.totalCount = newItems.totalCount;
        currentCache.pageNumber = newItems.pageNumber;
        currentCache.totalPageCount = newItems.totalPageCount;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),
    getQueryOverDueAttendancesReturnGroupedByPage: builder.query<
      AttendancesProps,
      PageRequest
    >({
      query: ({ GroupId, PageNumber, PageCount }) => ({
        url: `${URL}/QueryOverDueAttendancesReturnGroupedByPage`,
        method: 'GET',
        params: { GroupId, PageCount, PageNumber },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ taskListId }) => ({
                type: 'Task' as const,
                id: taskListId,
              })),
              { type: 'Task', id: 'Overdue' },
            ]
          : [{ type: 'Task', id: 'Overdue' }],
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems) => {
        const updatedData = newItems.data
          .map((newTask) => {
            const existingTaskIndex = currentCache.data.findIndex(
              (task) => task.taskListId === newTask.taskListId,
            );

            if (existingTaskIndex !== -1) {
              currentCache.data[existingTaskIndex] = newTask;
              return null;
            }

            return newTask;
          })
          .filter((task): task is TaskProps => task !== null);
        // 合併新資料和已存在的資料
        const mergedData = [...updatedData, ...currentCache.data].sort(
          (a, b) => b.taskListId - a.taskListId,
        );
        currentCache.data = mergedData;
        currentCache.currentPageCount = newItems.currentPageCount;
        currentCache.pageCount = newItems.pageCount;
        currentCache.totalCount = newItems.totalCount;
        currentCache.pageNumber = newItems.pageNumber;
        currentCache.totalPageCount = newItems.totalPageCount;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),
    getQueryDoneAttendancesReturnGroupedByPage: builder.query<
      AttendancesProps,
      PageRequest
    >({
      query: ({ GroupId, PageNumber, PageCount }) => ({
        url: `${URL}/QueryDoneAttendancesReturnGroupedByPage`,
        method: 'GET',
        params: { GroupId, PageCount, PageNumber },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ taskListId }) => ({
                type: 'Task' as const,
                id: taskListId,
              })),
              { type: 'Task', id: 'Done' },
            ]
          : [{ type: 'Task', id: 'Done' }],
      merge: (currentCache, newItems) => {
        const updatedData = newItems.data
          .map((newTask) => {
            const existingTaskIndex = currentCache.data.findIndex(
              (task) => task.taskListId === newTask.taskListId,
            );

            if (existingTaskIndex !== -1) {
              currentCache.data[existingTaskIndex] = newTask;
              return null;
            }

            return newTask;
          })
          .filter((task): task is TaskProps => task !== null);
        // 合併新資料和已存在的資料
        const mergedData = [...updatedData, ...currentCache.data].sort(
          (a, b) => b.taskListId - a.taskListId,
        );
        currentCache.data = mergedData;
        currentCache.currentPageCount = newItems.currentPageCount;
        currentCache.pageCount = newItems.pageCount;
        currentCache.totalCount = newItems.totalCount;
        currentCache.pageNumber = newItems.pageNumber;
        currentCache.totalPageCount = newItems.totalPageCount;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),

    /// 取得report task list 選項
    /// request.TaskListStatuses 為 null 會回傳所有
    getQueryTaskListReportOptionByPageFilter: builder.query<
      ByPageData<TaskListReportOption>,
      TaskListQueryReportOptionsRequest
    >({
      query: (data) => ({
        url: `${URL}/QueryTaskListReportOptionByPageFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),

    /// 取得TaskList previe photo，每個TaskList 最多6個
    getQueryTaskListPreviewPhotoByPageFilter: builder.query<
      ByPageData<TaskListBriefPhoto>,
      TaskListQueryPreivewPhotoRequest
    >({
      query: (data) => ({
        url: `${URL}/QueryTaskListPreviewPhotoByPageFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),

    /// 取得TaskList photo
    getTaskListPhotoByPage: builder.query<any, TaskListQueryPhotoRequest>({
      query: (data) => ({
        url: `${URL}/GetTaskListPhotoByPage`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        const res = response.data.data;
        // 按照日期分組
        const groupedPhotos = res.data.reduce((acc, photo) => {
          const date = format(parseISO(photo.created), 'yyyy-MM-dd');
          if (!acc[date]) {
            acc[date] = {
              date,
              photos: [],
            };
          }
          acc[date].photos.push(photo);
          return acc;
        }, {});

        // 把結果轉成 Array
        const result = Object.values(groupedPhotos);
        return result;
      },
    }),

    getTaskListDetail: builder.query<TaskDetail, TaskListId>({
      query: ({ TaskListId }) => ({
        url: `${URL}/GetTaskListDetail`,
        method: 'GET',
        params: { TaskListId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'TaskListDetail', id: arg.TaskListId },
      ],
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.TaskListId}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.TaskListId !== previousArg?.TaskListId;
      },
    }),
    getTaskListSubTaskDetail: builder.query<SubTaskDetail, TaskListSubTaskId>({
      query: ({ TaskListSubTaskId }) => ({
        url: `${URL}/GetTaskListSubTaskDetail`,
        method: 'GET',
        params: { TaskListSubTaskId },
      }),
      transformResponse: (response) => {
        // const { from, to } = response.data.data;
        // const newFrom =
        //   from && isValid(parseISO(from)) ? new Date(from) : undefined;
        // const newTo = to && isValid(parseISO(to)) ? new Date(to) : undefined;
        return response.data.data;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.TaskListSubTaskId}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.TaskListSubTaskId !== previousArg?.TaskListSubTaskId;
      },
      providesTags: (result, error, arg) => [
        { type: 'SubTaskDetail', id: arg.TaskListSubTaskId },
      ],
    }),
    updateTaskListSubTaskStatus: builder.mutation<any, UpdateTaskStatus>({
      query: ({ TaskListSubTaskId, Status }) => ({
        url: `${URL}/UpdateTaskListSubTaskStatus`,
        method: 'PUT',
        data: { TaskListSubTaskId, Status },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(scheduleApi.util.invalidateTags(['ScheduleTask']));
        } catch (error) {
          throw new Error(error.message);
        }
      },
      invalidatesTags: () => [{ type: 'Task', id: 'Done' }],
    }),
    getTaskListComments: builder.query<TaskListComment[], TaskListId>({
      query: ({ TaskListId }) => ({
        url: `${URL}/GetTaskListComments`,
        method: 'GET',
        params: { TaskListId },
      }),
      providesTags: () => [{ type: 'Comments' }],
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.TaskListId !== previousArg?.TaskListId;
      },
    }),
    getTaskListHistoryDetails: builder.query<
      TaskListHistoryDetails,
      TaskListId
    >({
      query: ({ TaskListId }) => ({
        url: `${URL}/GetTaskListHistoryDetails`,
        method: 'GET',
        params: { TaskListId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    createTaskList: builder.mutation<any, CreateTaskList>({
      query: (data) => ({
        url: `${URL}/CreateTaskList`,
        method: 'POST',
        data,
      }),
      async onQueryStarted({ ...arg }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          BaseToast('success', `${arg.Title} created!`);
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      // invalidatesTags: ['TaskList'],
    }),
    updateTaskList: builder.mutation<any, UpdateTaskList>({
      query: (data) => ({
        url: `${URL}/UpdateTaskList`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          BaseToast('success', `${arg.Title} updated!`);
          dispatch(
            taskListApi.util.invalidateTags([
              { type: 'ProjectUsers', id: arg.ProjectId },
              { type: 'TaskListDetail', id: arg.TaskListId },
            ]),
          );
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Task', id: arg.TaskListId },
      ],
    }),
    duplicateTaskList: builder.mutation<any, DuplicateTaskList>({
      query: (data) => ({
        url: `${URL}/DuplicateTaskList`,
        method: 'POST',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(resetMyPage());
          BaseToast('success', 'Successfully duplicated task');
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      // invalidatesTags: (result, error, arg) => [{ type: 'TaskList' }],
    }),
    deleteTaskList: builder.mutation<any, TaskListId>({
      query: (data) => ({
        url: `${URL}/DeleteTaskList`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(resetMyPage());
          dispatch(
            taskListApi.util.invalidateTags([
              { type: 'Task', id: arg.TaskListId },
            ]),
          );
          // BaseToast('success', 'Successfully deleted task');
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
    }),
    createTaskListSubTask: builder.mutation<any, CreateTaskListSubTask>({
      query: (data) => ({
        url: `${URL}/CreateTaskListSubTask`,
        method: 'POST',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          BaseToast('success', `${arg.Title} created!`);
          dispatch(
            taskListApi.util.invalidateTags([
              { type: 'TaskListDetail', id: arg.TaskListId },
            ]),
          );
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Task', id: arg.TaskListId },
        { type: 'Task', id: 'Overdue' },
      ],
    }),
    updateTaskListSubTask: builder.mutation<any, UpdateTaskListSubTask>({
      query: (data) => ({
        url: `${URL}/UpdateTaskListSubTask`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(scheduleApi.util.invalidateTags(['ScheduleTask']));
          BaseToast('success', `${arg.Title} updated!`);
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'SubTaskDetail' },
        { type: 'TaskListDetail' },
      ],
    }),
    deleteTaskListSubTask: builder.mutation<any, TaskListSubTaskId>({
      query: (data) => ({
        url: `${URL}/DeleteTaskListSubTask`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted({ ...arg }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(scheduleApi.util.invalidateTags(['ScheduleTask']));
          dispatch(taskListApi.util.invalidateTags(['TaskListDetail']));
          // BaseToast('success', 'Successfully deleted subtask');
        } catch (error) {
          BaseToast('error', error.message);
          throw new Error(error.message);
        }
      },
      // invalidatesTags: (result, error, arg) => [{ type: 'TaskListDetail' }],
    }),
    createTaskListComment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `${URL}/CreateTaskListComment`,
        method: 'POST',
        data,
      }),
      // async onQueryStarted({ ...arg }, { dispatch, getState, queryFulfilled }) {
      //   try {
      //     const { data: newComment } = await queryFulfilled;
      //     dispatch(
      //       taskListApi.util.updateQueryData(
      //         'getTaskListComments',
      //         { TaskListId: newComment.TaskListId },
      //         (draft) => {
      //           draft.push(newComment);
      //         },
      //       ),
      //     );
      //   } catch (error) {}
      // },
      invalidatesTags: (result, error, arg) => [{ type: 'Comments' }],
    }),
  }),
});

export const {
  useGetQueryAttendancesReturnGroupedByPageQuery,
  useGetQueryOverDueAttendancesReturnGroupedByPageQuery,
  useGetQueryDoneAttendancesReturnGroupedByPageQuery,
  useGetTaskListDetailQuery,
  useGetTaskListSubTaskDetailQuery,
  useUpdateTaskListSubTaskStatusMutation,
  useGetTaskListCommentsQuery,
  useGetTaskListHistoryDetailsQuery,
  useCreateTaskListMutation,
  useUpdateTaskListMutation,
  useDuplicateTaskListMutation,
  useDeleteTaskListMutation,
  useCreateTaskListSubTaskMutation,
  useUpdateTaskListSubTaskMutation,
  useDeleteTaskListSubTaskMutation,
  useCreateTaskListCommentMutation,
  useGetQueryTaskListReportOptionByPageFilterQuery,
  useGetQueryTaskListPreviewPhotoByPageFilterQuery,
  useGetTaskListPhotoByPageQuery,
} = taskListApi;

export function queryAttendancesReturnGroupedByPage({
  GroupId,
  PageNumber,
  PageCount,
}: PageRequest) {
  return request({
    url: `${URL}/QueryAttendancesReturnGroupedByPage?GroupId=${GroupId}&PageCount=${PageCount}&PageNumber=${PageNumber}`,
    method: 'GET',
  });
}

export function queryDoneAttendancesReturnGroupedByPage({
  GroupId,
  PageNumber,
  PageCount,
}: PageRequest) {
  return request({
    url: `${URL}/QueryDoneAttendancesReturnGroupedByPage?GroupId=${GroupId}&PageCount=${PageCount}&PageNumber=${PageNumber}`,
    method: 'GET',
  });
}

export function queryOverDueAttendancesReturnGroupedByPage({
  GroupId,
  PageNumber,
  PageCount,
}: PageRequest) {
  return request({
    url: `${URL}/QueryOverDueAttendancesReturnGroupedByPage?GroupId=${GroupId}&PageCount=${PageCount}&PageNumber=${PageNumber}`,
    method: 'GET',
  });
}

export function getTaskListDetail({ TaskListId }: TaskListId) {
  return request({
    url: `${URL}/GetTaskListDetail?TaskListId=${TaskListId}`,
    method: 'GET',
  });
}

export function getTaskListSubTaskDetail({
  TaskListSubTaskId,
}: TaskListSubTaskId) {
  return request({
    url: `${URL}/GetTaskListSubTaskDetail?TaskListSubTaskId=${TaskListSubTaskId}`,
    method: 'GET',
  });
}

export function updateTaskListSubTaskStatus(data: UpdateTaskStatus) {
  return request({
    url: `${URL}/UpdateTaskListSubTaskStatus`,
    method: 'PUT',
    data,
  });
}

// export function getTaskListComments({ TaskListId }: TaskListId) {
//   return request({
//     url: `${URL}/GetTaskListComments?TaskListId=${TaskListId}`,
//     method: 'GET',
//   });
// }

export function getTaskListHistoryDetails({ TaskListId }: TaskListId) {
  return request({
    url: `${URL}/GetTaskListHistoryDetails?TaskListId=${TaskListId}`,
    method: 'GET',
  });
}

export function createTaskList(data: CreateTaskList) {
  return request({
    url: `${URL}/CreateTaskList`,
    method: 'POST',
    data,
  });
}

export function updateTaskList(data: UpdateTaskList) {
  return request({
    url: `${URL}/UpdateTaskList`,
    method: 'PUT',
    data,
  });
}

export function duplicateTaskList(data: DuplicateTaskList) {
  return request({
    url: `${URL}/DuplicateTaskList`,
    method: 'POST',
    data,
  });
}

export function deleteTaskList(data: TaskListId) {
  return request({
    url: `${URL}/DeleteTaskList`,
    method: 'DELETE',
    data,
  });
}

export function createTaskListSubTask(data: CreateTaskListSubTask) {
  return request({
    url: `${URL}/CreateTaskListSubTask`,
    method: 'POST',
    data,
  });
}

export function updateTaskListSubTask(data: UpdateTaskListSubTask) {
  return request({
    url: `${URL}/UpdateTaskListSubTask`,
    method: 'PUT',
    data,
  });
}

export function deleteTaskListSubTask(data: TaskListSubTaskId) {
  return request({
    url: `${URL}/DeleteTaskListSubTask`,
    method: 'DELETE',
    data,
  });
}

export function createTaskListComment(data: FormData) {
  return request({
    url: `${URL}/CreateTaskListComment`,
    method: 'POST',
    data,
  });
}
