import { createApi } from '@reduxjs/toolkit/query/react';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { ScheduleRequest, ScheduleResponse } from './interface';

const URL = '/api/Schedule';

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ScheduleTask'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    querySchedule: builder.query<ScheduleResponse, ScheduleRequest>({
      query: (data) => ({
        url: `${URL}/QuerySchedule`,
        method: 'POST',
        data,
      }),
      providesTags: [{ type: 'ScheduleTask' }],
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.GroupId}-${queryArgs.StartDate}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.StartDate !== previousArg?.StartDate;
      },
    }),
    queryMonthlySchedule: builder.query<ScheduleResponse, ScheduleRequest>({
      query: (data) => {
        return {
          url: `${URL}/QuerySchedule`,
          method: 'POST',
          data,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.GroupId}-monthly${queryArgs.StartDate}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.StartDate !== previousArg?.StartDate ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const { useQueryScheduleQuery, useQueryMonthlyScheduleQuery } =
  scheduleApi;
