import { createSlice } from '@reduxjs/toolkit';
import BaseToast from '@/components/base/BaseToast/BaseToast';
import {
  SubTaskDetail,
  TaskDetail,
  TaskListComment,
  TaskListHistoryDetails,
  AttendancesProps,
  TaskProps,
} from './interface';
import { taskListApi } from './taskListAPI';

interface InitialState {
  taskListId: number;
  taskListSubTaskId: number;
  isLoading: boolean;
  menuStatus: string;
  tabStatus: string;
  subTaskListDrawer: boolean;
  attendancesPageMap: { [key: string]: number };
  attendances: AttendancesProps;
  doneAttendances: AttendancesProps;
  overDueAttendances: AttendancesProps;
  taskListDetails: TaskDetail;
  subTaskDetails: SubTaskDetail;
  taskListComments: TaskListComment[];
  taskListHistoryDetails: TaskListHistoryDetails[];
  // taskListHistoryTemplates: any[];
}

const initialState: InitialState = {
  taskListId: 0,
  taskListSubTaskId: 0,
  isLoading: false,
  menuStatus: 'All',
  tabStatus: 'To-Do', // To-Do | Comment | History | Details
  subTaskListDrawer: false,
  attendancesPageMap: {},
  attendances: {
    currentPageCount: 0,
    pageNumber: 1,
    pageCount: 0,
    totalCount: 0,
    totalPageCount: 0,
    data: [],
  },
  doneAttendances: {
    currentPageCount: 0,
    pageNumber: 1,
    pageCount: 0,
    totalCount: 0,
    totalPageCount: 0,
    data: [],
  },
  overDueAttendances: {
    currentPageCount: 0,
    pageNumber: 1,
    pageCount: 0,
    totalCount: 0,
    totalPageCount: 0,
    data: [],
  },
  taskListDetails: {
    taskListId: 0,
    title: '',
    updated: '',
    project: {
      projectId: 0,
      name: '',
      color: '',
    },
    assignUserIds: [],
    notifyUserIds: [],
    subTasks: [],
    users: [],
    assignedTaskListSubTaskCount: 0,
    completedTaskListSubTaskCount: 0,
    totalTaskListSubTaskCount: 0,
  },
  subTaskDetails: {
    taskListId: 0,
    taskListSubTaskId: 0,
    taskListTitle: '',
    title: '',
    note: '',
    from: '',
    to: '',
    status: 1,
    isAllDay: true,
    assignUserIds: [],
    assignUsers: [],
  },
  taskListComments: [],
  taskListHistoryDetails: [],
};

const taskListSlice = createSlice({
  name: 'taskList',
  initialState,
  reducers: {
    setMenuStatus: (state, action) => {
      state.menuStatus = action.payload;
    },
    setTabStatus: (state, action) => {
      state.tabStatus = action.payload;
    },
    setSubTaskDrawerToggle: (state, action) => {
      state.subTaskListDrawer = action.payload;
    },
    setClearTaskList: (state) => {
      state.taskListId = 0;
      state.attendances = {
        currentPageCount: 0,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        totalPageCount: 0,
        data: [],
      };
      state.doneAttendances = {
        currentPageCount: 0,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        totalPageCount: 0,
        data: [],
      };
      state.overDueAttendances = {
        currentPageCount: 0,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        totalPageCount: 0,
        data: [],
      };
      state.taskListDetails = {
        taskListId: 0,
        title: '',
        updated: '',
        project: {
          projectId: 0,
          name: '',
          color: '',
        },
        assignUserIds: [],
        notifyUserIds: [],
        subTasks: [],
        users: [],
        assignedTaskListSubTaskCount: 0,
        completedTaskListSubTaskCount: 0,
        totalTaskListSubTaskCount: 0,
      };
      state.taskListComments = [];
      state.taskListHistoryDetails = [];
    },
    setClearTaskListDetails: (state) => {
      state.taskListDetails = {
        taskListId: 0,
        title: '',
        updated: '',
        project: {
          projectId: 0,
          name: '',
          color: '',
        },
        assignUserIds: [],
        notifyUserIds: [],
        subTasks: [],
        users: [],
        assignedTaskListSubTaskCount: 0,
        completedTaskListSubTaskCount: 0,
        totalTaskListSubTaskCount: 0,
      };
      state.taskListComments = [];
      state.taskListHistoryDetails = [];
    },
    setClearSubTaskDetails: (state) => {
      state.subTaskDetails = {
        taskListId: 0,
        taskListSubTaskId: 0,
        taskListTitle: '',
        title: '',
        note: '',
        from: '',
        to: '',
        status: 1,
        isAllDay: true,
        assignUserIds: [],
        assignUsers: [],
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectTaskListId: (state, action) => {
      state.taskListId = action.payload;
    },
    setSelectTaskListSubTaskId: (state, action) => {
      state.taskListSubTaskId = action.payload;
    },
    incrementMyPage: (state) => {
      state.attendances.pageNumber += 1;
    },
    incrementOverduePage: (state) => {
      state.overDueAttendances.pageNumber += 1;
    },
    incrementDonePage: (state) => {
      state.doneAttendances.pageNumber += 1;
    },
    resetMyPage: (state) => {
      state.attendances.pageNumber = 1;
      state.overDueAttendances.pageNumber = 1;
      state.doneAttendances.pageNumber = 1;
    },
    setTaskPageMap: (state, action) => {
      state.attendancesPageMap = {
        ...state.attendancesPageMap,
        ...action.payload,
      };
    },
    setAssignedTaskListSubTaskCount: (state, action) => {
      const {taskListId} = action.payload;
      state.attendances.data = state.attendances.data.map((task) => {
        if (task.taskListId === taskListId) {
          return {
            ...task,
            assignedTaskListSubTaskCount: task.assignedTaskListSubTaskCount + 1,
          };
        }
        return task;
      });
    },
    setTotalTaskListSubTaskCount: (state, action) => {
      const {taskListId} = action.payload;
      state.attendances.data = state.attendances.data.map((task) => {
        if (task.taskListId === taskListId) {
          return {
            ...task,
            totalTaskListSubTaskCount: task.totalTaskListSubTaskCount + 1,
          };
        }
        return task;
      });
    },
    setUpdateTaskDetail: (state, action) => {
      // 找到該 taskListId 的 task
      const {
        taskListId,
        taskListTitle,
        projectId,
        projectName,
        projectIcon,
        projectColor,
      } = action.payload;
      state.attendances.data = state.attendances.data.map((task) => {
        if (task.taskListId === taskListId) {
          return {
            ...task,
            taskListTitle,
            projectId,
            project: {
              projectId,
              name: projectName,
              icon: projectIcon,
              color: projectColor,
            },
          };
        }
        return task;
      });
    },
    setUpdateSubTaskStatusAndTaskCount: (state, action) => {
      const { taskListId, taskListSubTaskId, newStatus, prevStatus, userId } =
        action.payload;

      const updateSubTaskStatus = (subTasks) => {
        return subTasks.map((subTask) => {
          if (subTask.taskListSubTaskId === taskListSubTaskId) {
            return { ...subTask, status: newStatus };
          }
          return subTask;
        });
      };

      const updateTaskStatusCount = (tasks, isFinalUpdate = false) => {
        return tasks.map((task) => {
          if (task.taskListId === taskListId) {
            const updatedTask = { ...task };
            if (!isFinalUpdate) {
              if (newStatus === 4 && prevStatus.status !== 4) {
                updatedTask.completedTaskListSubTaskCount++;
              }
            }
            if (
              prevStatus.status === 4 &&
              newStatus !== 4 &&
              updatedTask.completedTaskListSubTaskCount > 0
            ) {
              updatedTask.completedTaskListSubTaskCount--;
            }
            return updatedTask;
          }
          return task;
        });
      };

      const moveCompletedTaskToDone = (tasks, doneTasks) => {
        return tasks.filter((task) => {
          if (
            task.taskListId === taskListId &&
            task.completedTaskListSubTaskCount ===
              task.totalTaskListSubTaskCount
          ) {
            doneTasks.unshift(task);
            return false;
          }
          return true;
        });
      };

      const moveTaskToAppropriateList = (
        tasks,
        overdueTasks,
        completedTasks,
      ) => {
        return completedTasks.filter((task) => {
          if (task.taskListId === taskListId) {
            if (
              task.completedTaskListSubTaskCount <
              task.totalTaskListSubTaskCount
            ) {
              console.info(
                '🚀 ~ totalOverDueListSubTaskCount ~:',
                task.totalOverDueListSubTaskCount,
              );
              if (task.totalOverDueListSubTaskCount !== 0) {
                overdueTasks.unshift(task);
              }
              tasks.unshift(task);

              return false;
            }
          }
          return true;
        });
      };

      // 更新 taskListDetails的subtasks的status
      state.taskListDetails.subTasks = updateSubTaskStatus(
        state.taskListDetails.subTasks,
      );

      // 更新subTaskDetail
      state.subTaskDetails.status = newStatus;

      // 更新 attendances data 中的任務，根據新舊 status 更新 completedTaskListSubTaskCount
      state.attendances.data = updateTaskStatusCount(state.attendances.data);

      // 檢查task是否存在於 overDueAttendances 中
      const isTaskInOverdueAttendances = state.overDueAttendances.data.some(
        (task) => task.taskListId === taskListId,
      );

      // 如果task存在於 overDueAttendances 中，更新它們的 completedTaskListSubTaskCount
      if (isTaskInOverdueAttendances) {
        state.overDueAttendances.data = updateTaskStatusCount(
          state.overDueAttendances.data,
        );

        // 從 overDueAttendances 中移除已完成的任務
        state.overDueAttendances.data = state.overDueAttendances.data.filter(
          (task) => {
            if (
              task.taskListId === taskListId &&
              task.completedTaskListSubTaskCount ===
                task.totalTaskListSubTaskCount
            ) {
              return false;
            }
            return true;
          },
        );
      }

      // 從 attendances 中移除已完成的任務，並將其移動到 doneAttendances
      state.attendances.data = moveCompletedTaskToDone(
        state.attendances.data,
        state.doneAttendances.data,
      );

      // 更新 doneAttendances data 中的任務
      state.doneAttendances.data = updateTaskStatusCount(
        state.doneAttendances.data,
        true, // 這裡表示是最終更新，不再增減 completedTaskListSubTaskCount
      );

      // 從 doneAttendances 中移除未完成的任務，並將移動回 attendances 或 overDueAttendances
      state.doneAttendances.data = moveTaskToAppropriateList(
        state.attendances.data,
        state.overDueAttendances.data,
        state.doneAttendances.data,
      );
    },

    setCheckSubTaskIsOverdue: (state, action) => {
      const { subTasks, userId } = action.payload;
      const now = new Date();

      subTasks.forEach((subTask) => {
        if (new Date(subTask.to) < now) {
          state.overDueAttendances.data = state.overDueAttendances.data.map(
            (task) => {
              if (task.taskListId === subTask.taskListId) {
                return {
                  ...task,
                  totalOverDueListSubTaskCount:
                    task.totalOverDueListSubTaskCount + 1,
                  assignedOverDueTaskListSubTaskCount:
                    task.assignedOverDueTaskListSubTaskCount +
                    (subTask.assignUserIds.includes(userId) ? 1 : 0),
                };
              }
              return task;
            },
          );
        }
      });
    },
    setDeleteSubTaskChangeTaskCount: (state, action) => {
      const {taskListId} = action.payload;
      const {taskListSubTaskId} = action.payload;
      const {userId} = action.payload;
      state.attendances.data = state.attendances.data.map((task) => {
        if (task.taskListId === taskListId) {
          let assignedCount = task.assignedTaskListSubTaskCount;
          let totalCount = task.totalTaskListSubTaskCount;

          state.taskListDetails.subTasks
            .map((subTask) => {
              if (subTask.taskListSubTaskId === taskListSubTaskId) {
                if (subTask.assignUserIds.includes(userId)) {
                  assignedCount--;
                }
                return null;
              }
              return subTask;
            })
            .filter((subTask) => subTask !== null);
          totalCount--;
          return {
            ...task,
            assignedTaskListSubTaskCount: Math.max(0, assignedCount),
            totalTaskListSubTaskCount: Math.max(0, totalCount),
          };
        }
        return task;
      });
    },
    setCommentCount: (state, action) => {
      const {taskListId} = action.payload;
      state.attendances.data = state.attendances.data.map((task) => {
        if (task.taskListId === taskListId) {
          return {
            ...task,
            taskListCommentCount: task.taskListCommentCount + 1,
          };
        }
        return task;
      });
      const isTaskInOverdueAttendances = state.overDueAttendances.data.some(
        (task) => task.taskListId === taskListId,
      );

      if (isTaskInOverdueAttendances) {
        state.overDueAttendances.data = state.overDueAttendances.data.map(
          (task) => {
            if (task.taskListId === taskListId) {
              return {
                ...task,
                taskListCommentCount: task.taskListCommentCount + 1,
              };
            }
            return task;
          },
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        taskListApi.endpoints.getQueryAttendancesReturnGroupedByPage
          .matchFulfilled,
        (state, action) => {
          // 儲存已存在的Id
          const existingTaskIds = new Set(
            state.attendances.data.map((task) => task.taskListId),
          );

          // 過濾新資料 排除已存在的Id
          const newData = action.payload.data.filter(
            (task: { taskListId: number }) =>
              !existingTaskIds.has(task.taskListId),
          );

          const updateData = action.payload.data
            .map((newTask) => {
              const existingTaskIndex = state.attendances.data.findIndex(
                (task) => task.taskListId === newTask.taskListId,
              );
              if (existingTaskIndex !== -1) {
                state.attendances.data[existingTaskIndex] = newTask;
                return null;
              }
              return newTask;
            })
            .filter((task): task is TaskProps => task !== null);

          // 合併新資料和已存在的資料
          const mergedData = [...updateData, ...state.attendances.data].sort(
            (a, b) => b.taskListId - a.taskListId,
          );
          state.attendances = {
            ...state.attendances,
            data: mergedData,
            currentPageCount: action.payload.currentPageCount,
            pageCount: action.payload.pageCount,
            totalCount: action.payload.totalCount,
            totalPageCount: action.payload.totalPageCount,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        taskListApi.endpoints.getQueryOverDueAttendancesReturnGroupedByPage
          .matchFulfilled,
        (state, action) => {
          const existingTaskIds = new Set(
            state.overDueAttendances.data.map((task) => task.taskListId),
          );
          const newData = action.payload.data.filter(
            (task: { taskListId: number }) =>
              !existingTaskIds.has(task.taskListId),
          );
          const mergedData = [
            ...newData,
            ...state.overDueAttendances.data,
          ].sort((a, b) => b.taskListId - a.taskListId);
          state.overDueAttendances = {
            ...state.overDueAttendances,
            data: mergedData,
            currentPageCount: action.payload.currentPageCount,
            pageCount: action.payload.pageCount,
            totalCount: action.payload.totalCount,
            totalPageCount: action.payload.totalPageCount,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        taskListApi.endpoints.getQueryDoneAttendancesReturnGroupedByPage
          .matchFulfilled,
        (state, action) => {
          const existingTaskIds = new Set(
            state.doneAttendances.data.map((task) => task.taskListId),
          );
          const newData = action.payload.data.filter(
            (task: { taskListId: number }) =>
              !existingTaskIds.has(task.taskListId),
          );
          const mergedData = [...newData, ...state.doneAttendances.data].sort(
            (a, b) => b.taskListId - a.taskListId,
          );
          state.doneAttendances = {
            ...state.doneAttendances,
            data: mergedData,
            currentPageCount: action.payload.currentPageCount,
            pageCount: action.payload.pageCount,
            totalCount: action.payload.totalCount,
            totalPageCount: action.payload.totalPageCount,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        taskListApi.endpoints.getTaskListDetail.matchFulfilled,
        (state, action) => {
          console.debug('action', action.payload);
          state.taskListDetails = action.payload;
        },
      )
      .addMatcher(
        taskListApi.endpoints.getTaskListSubTaskDetail.matchFulfilled,
        (state, action) => {
          state.taskListSubTaskId =
            action.meta.arg.originalArgs.TaskListSubTaskId;
          state.subTaskDetails = action.payload;
        },
      )
      .addMatcher(
        taskListApi.endpoints.deleteTaskList.matchFulfilled,
        (state, action) => {
          const taskListId = action.meta.arg.originalArgs.TaskListId;
          const removeTaskById = (tasks: TaskProps[]) =>
            tasks.filter(
              (task: { taskListId: number }) => task.taskListId !== taskListId,
            );
          // 從陣列中移除不是TaskListId的資料
          state.attendances.data = removeTaskById(state.attendances.data);
          state.overDueAttendances.data = removeTaskById(
            state.overDueAttendances.data,
          );
          state.doneAttendances.data = removeTaskById(
            state.doneAttendances.data,
          );
        },
      )
      .addMatcher(
        taskListApi.endpoints.updateTaskListSubTaskStatus.matchFulfilled,
        (state, action) => {},
      )
      .addMatcher(
        taskListApi.endpoints.getTaskListComments.matchFulfilled,
        (state, action) => {
          state.taskListComments = action.payload;
        },
      );
  },
});

export const {
  setMenuStatus,
  setTabStatus,
  setSubTaskDrawerToggle,
  setClearTaskListDetails,
  setClearSubTaskDetails,
  incrementMyPage,
  incrementDonePage,
  incrementOverduePage,
  setAssignedTaskListSubTaskCount,
  setTotalTaskListSubTaskCount,
  setUpdateSubTaskStatusAndTaskCount,
  setDeleteSubTaskChangeTaskCount,
  setCommentCount,
  setIsLoading,
  setSelectTaskListId,
  setSelectTaskListSubTaskId,
  resetMyPage,
  setCheckSubTaskIsOverdue,
  setClearTaskList,
  setUpdateTaskDetail,
} = taskListSlice.actions;

export default taskListSlice.reducer;
